export const getMostCommonCurrency = (reviews: any): string | null => {
  const reviewsArray = Array.isArray(reviews)
    ? reviews
    : Object.values(reviews);

  if (!Array.isArray(reviewsArray)) {
    console.error("Expected reviews to be an array but received:", reviews);
    return null;
  }

  const currencyCount: { [key: string]: number } = reviewsArray.reduce(
    (acc: { [key: string]: number }, review) => {
      const currency = review.currency || "USD";
      acc[currency] = (acc[currency] || 0) + 1;
      return acc;
    },
    {},
  );

  const mostCommonCurrency = Object.keys(currencyCount).reduce(
    (mostCommon: string | null, currentCurrency: string) => {
      if (
        mostCommon === null ||
        currencyCount[currentCurrency] > currencyCount[mostCommon]
      ) {
        return currentCurrency;
      }
      return mostCommon;
    },
    null as string | null,
  );

  return mostCommonCurrency;
};
