import React from "react";
import { FaStar, FaRegStar, FaStarHalfAlt, FaStarHalf } from "react-icons/fa";
import { IoIosStarOutline, IoIosStarHalf, IoIosStar } from "react-icons/io";

interface RatingStarProps {
  averageRating: number;
  reviewsNumber: number;
}

const RatingStar: React.FC<RatingStarProps> = ({
  averageRating,
  reviewsNumber,
}) => (
  <div className="flex items-center text-sm ">
    {reviewsNumber === 0 ? (
      <div className="flex items-center mr-2">
        {/* {[...Array(5)].map((_, index) => (
                    <span key={index}>
                        <FaStar size={12} className="text-gray-300" />
                    </span>
                ))} */}
        <FaStar size={12} className="text-gray-300" />
        <div className="text-neutral-600 text-xs ml-1">No reviews</div>
      </div>
    ) : (
      <>
        <div className="flex items-center mr-2">
          <FaStar size={12} className="text-atoll-600" />
        </div>
        <span className="text-neutral-800">{averageRating.toFixed(1)}</span>
        {/* <div className="font-light text-neutral-500 ml-2 hover:text-atoll-700">
                    <a href="#reviews">{`${reviewsNumber} reviews`}</a>
                </div> */}
      </>
    )}
  </div>
);

export default RatingStar;
