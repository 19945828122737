import { ExchangeCurrency } from "@prisma/client";

const convertPrice = async (
  price: number,
  toCurrencyCode: string,
  rates: ExchangeCurrency | null,
) => {
  try {
    if (rates != null) {
      if (toCurrencyCode in rates) {
        const exchangeRate = rates[toCurrencyCode as keyof typeof rates];

        if (typeof exchangeRate === "number") {
          const convertedPrice = price * exchangeRate;
          return convertedPrice;
        } else {
          console.warn(
            "Exchange rate is not a number for currency code:",
            toCurrencyCode,
          );
        }
      } else {
        console.warn("Currency code not found in rates:", toCurrencyCode);
      }
    } else {
      console.warn("No currency rates available");
    }
  } catch (error) {
    console.error("Error during conversion:", error);
  }

  console.warn("Conversion failed, returning original price:", price);
  return price;
};

export default convertPrice;
