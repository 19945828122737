"use client";

import { useState, useEffect } from "react";
import convertPrice from "@/app/utils/CurrencyConverter";
import useCurrencies from "@/app/hooks/useCurrencies";
import { SafeUser } from "@/app/types";
import { ExchangeCurrency } from "@prisma/client";

const useCurrencyConversion = (
  salary: string | null | undefined,
  currentUser: SafeUser | null | undefined,
  rates: ExchangeCurrency | null,
) => {
  const [convertedSalary, setConvertedSalary] = useState<string | null>(null);
  const { getByCode } = useCurrencies();
  const userCurrency = getByCode(currentUser?.diver?.currency || "");

  useEffect(() => {
    const convertSalary = async () => {
      if (salary) {
        const toCurrency = userCurrency?.code || "";

        const [minSalary, maxSalary] = salary
          .split("-")
          .map((s) => parseFloat(s.trim()));

        if (!isNaN(minSalary)) {
          try {
            const convertedMin = await convertPrice(
              minSalary,
              toCurrency,
              rates,
            );

            let result = convertedMin.toFixed(0);

            if (!isNaN(maxSalary)) {
              const convertedMax = await convertPrice(
                maxSalary,
                toCurrency,
                rates,
              );

              result += ` - ${convertedMax.toFixed(0)}`;
            }

            setConvertedSalary(result);
          } catch (error) {
            console.error("Error during conversion:", error);
          }
        } else {
          console.warn("Invalid min salary:", minSalary);
          setConvertedSalary(salary);
        }
      } else {
        console.warn("No salary provided");
      }
    };

    convertSalary();
  }, [salary, userCurrency]);

  return convertedSalary;
};

export default useCurrencyConversion;
