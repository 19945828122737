"use client";

import { SafeDiveCenterUserReviews, SafeUser } from "@/app/types";
import React from "react";
import RatingStar from "../RatingStar";
import { FaClock } from "react-icons/fa";
import { BiMoneyWithdraw } from "react-icons/bi";
import HeartButton from "../HeartButton";
import Image from "next/image";
import useCurrencies from "@/app/hooks/useCurrencies";
import { getMostCommonCurrency } from "@/app/utils/getMostCommonCurrency";
import useCurrencyConversion from "@/app/hooks/useCurrencyConversion";
import { ExchangeCurrency } from "@prisma/client";

interface CenterCardProps {
  center: SafeDiveCenterUserReviews;
  currentUser?: SafeUser | null;
  rates: ExchangeCurrency | null;
}

const CenterCard: React.FC<CenterCardProps> = ({
  center,
  currentUser,
  rates,
}) => {
  const reviews = center.reviews || [];
  const mostCommonCurrencyCode = getMostCommonCurrency(reviews);
  const { getByCode } = useCurrencies();
  const currencyOption = getByCode(mostCommonCurrencyCode || "USD");
  const userCurrency = getByCode(currentUser?.diver?.currency || "");

  const shouldConvert =
    currentUser && userCurrency && userCurrency?.code !== currencyOption?.code;

  const convertedSalary = shouldConvert
    ? useCurrencyConversion(center?.averageSalary, currentUser, rates)
    : center?.averageSalary;

  const displayCurrency =
    currencyOption?.symbol === "$" && userCurrency?.symbol === "$"
      ? currencyOption?.code
      : userCurrency?.symbol || currencyOption?.symbol;

  const centerData = {
    name: center?.user.name,
    agency: center?.agency,
    city: center?.city,
    country: center?.country,
  };

  const urlWithParams = `/center/${center?.id}?centerData=${encodeURIComponent(
    JSON.stringify(centerData),
  )}`;

  return (
    <div className="relative flex flex-col sm:flex-row my-4 py-4 px-4 sm:px-6 border rounded-md shadow-md">
      <div className="flex flex-grow space-x-4 w-full">
        <div className="relative w-10 h-10 sm:w-12 sm:h-12">
          <Image
            src={center.photo || "/images/logo-scuba-big.png"}
            alt={`${center.user.name} logo`}
            // fill
            style={{ objectFit: "cover" }}
            className="rounded-full"
            loading="lazy"
            // sizes="(max-width: 640px) 20vw,
            //     (max-width: 768px) 15vw,
            //     (max-width: 1024px) 10vw,
            //     8vw"
            width={50}
            height={50}
            quality={60}
          />
        </div>
        <div>
          <div className="flex flex-row w-full justify-between items-center">
            <a
              href={urlWithParams}
              rel="noopener noreferrer"
              className="col-span-1 cursor-pointer group"
            >
              <h2 className="sm:text-lg text-md font-semibold capitalize mr-7">
                {center.user.name}
              </h2>
            </a>
            {!currentUser ||
            (currentUser && currentUser.userType === "Diver") ? (
              <div className="absolute top-4 right-6">
                <HeartButton
                  diveCenterId={center.id}
                  currentUser={currentUser}
                />
              </div>
            ) : null}
          </div>
          <a
            href={urlWithParams}
            rel="noopener noreferrer"
            className="col-span-1 cursor-pointer group"
          >
            <p className="text-semibold sm:text-sm text-xs text-black">
              {center.agency}
            </p>
            <p className="text-gray-600 sm:text-md text-sm mt-1 mb-1 sm:mb-2">
              {center.city}, {center.country}
            </p>
            <RatingStar
              averageRating={center.averageRating || 0}
              reviewsNumber={center.totalReviews || 0}
            />
            <div className="sm:absolute text-right top-8 right-6 mt-3">
              <div className="flex flex-row items-center text-sm">
                <BiMoneyWithdraw size={14} className="mr-1" />
                {/* {center.averageSalary ? `${center.averageSalary} ${currencyOption?.symbol}/month` : ' No data yet'} */}
                {convertedSalary
                  ? `${convertedSalary} ${displayCurrency}/month`
                  : " No data yet"}
              </div>
              <div
                className={`sm:mt-2 mt-1 flex flex-row items-center text-sm text-atoll-700`}
              >
                <FaClock size={14} className="mr-1" />
                {center.averageWorkingHours
                  ? ` ${center.averageWorkingHours} hrs/day`
                  : " No data yet"}
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CenterCard;
