"use client";

import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

import { SafeUser } from "@/app/types";
import useFavoriteCenter from "../hooks/useFavoriteCenter";

interface HeartButtonProps {
  diveCenterId: string;
  currentUser?: SafeUser | null;
}

const HeartButton: React.FC<HeartButtonProps> = ({
  diveCenterId,
  currentUser,
}) => {
  const { hasFavorited, toggleFavorite } = useFavoriteCenter({
    diveCenterId,
    currentUser,
  });

  return (
    <div
      onClick={toggleFavorite}
      className="
      relative
      hover:opacity-80
      transition
      cursor-pointer
      w-5 h-5
      flex
      items-center
      justify-center
    "
    >
      {hasFavorited ? (
        <AiFillHeart size={18} className="fill-atoll-600" />
      ) : (
        <AiOutlineHeart size={18} className="fill-atoll-600" />
      )}
    </div>
  );
};

export default HeartButton;
